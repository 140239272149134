/* eslint-disable no-unused-vars */
import { useAppSelector } from '@/core/redux/utils'
import { RequiredDocumentEnum, useRequiredManualReviewFilesQuery } from '@/generated/graphql'

import { Pathname } from '../constants'

const orderedFileTypes = [
  RequiredDocumentEnum.GOVERNMENT_ID,
  RequiredDocumentEnum.INCORPORATION_DOC,
  RequiredDocumentEnum.DBA_DOC,
]

export interface FileUploadNavigation {
  nextPath: Pathname
  prevPath: Pathname
}

export const useInitialManualReviewScreen = (): Pathname => {
  const requiredFiles = useRequiredManualReviewFiles()
  if (requiredFiles?.includes(RequiredDocumentEnum.GOVERNMENT_ID))
    return Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT
  if (requiredFiles?.includes(RequiredDocumentEnum.INCORPORATION_DOC))
    return Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP
  if (requiredFiles?.includes(RequiredDocumentEnum.DBA_DOC)) return Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE
  return Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT
}

export const useRequiredManualReviewFiles = () => {
  const businessId = useAppSelector((state) => state.application.businessId)
  const { data } = useRequiredManualReviewFilesQuery({ variables: { businessId } })
  const requiredFiles = data?.business.onboarding?.application?.requiredDocumentUpload?.requiredDocuments
  return requiredFiles
}
/**
 * @returns Array of Pathnames in order based on required documents for manual review
 */
export const useFileUploadNavigation = () => {
  const requiredFiles = useRequiredManualReviewFiles()
  if (!requiredFiles)
    return (_currentPath: Pathname) => ({
      nextPath: Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS,
      prevPath: Pathname.FINISH_UP,
    })

  const orderedPaths = orderedFileTypes
    .filter((fileType) => requiredFiles.includes(fileType))
    .flatMap((curr) => {
      switch (curr) {
        case RequiredDocumentEnum.GOVERNMENT_ID:
          return [
            Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT,
            Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK,
          ]
        case RequiredDocumentEnum.INCORPORATION_DOC:
          return [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP]
        case RequiredDocumentEnum.DBA_DOC:
          return [Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE]
        default:
          return []
      }
    })
  orderedPaths.push(Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS)

  const getNextPath = (currentPath: Pathname) => {
    if (!orderedPaths.includes(currentPath)) return Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS
    //ProofOfIdentityFront is hardcoded to go to ProofOfIdentityBack. If skipped, send them to the screen after ProofOfIdentityBack, which at minimum will be the Thank You screen.
    if (currentPath === Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT)
      return orderedPaths[2] ?? Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS
    return orderedPaths[orderedPaths.indexOf(currentPath) + 1] ?? Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS
  }
  const getPreviousPath = (currentPath: Pathname) =>
    orderedPaths[orderedPaths.indexOf(currentPath) - 1] ?? Pathname.FINISH_UP
  return (currentPath: Pathname) => ({
    nextPath: getNextPath(currentPath),
    prevPath: getPreviousPath(currentPath),
  })
}
