import {
  BackArrowIcon,
  Box,
  FaviconIcon,
  HorizontalProgressBar,
  ICONS,
  MobileHeaderBar,
  ProgressNote,
  SizeEnum,
  Stepper,
} from '@northone/ui-components'
import { useMobileScreenSize } from '@northone/ui-theme'
import { ReactNode, Suspense } from 'react'
import { Pressable } from 'react-native'
import { matchPath, useLocation } from 'react-router-dom'

import { AppMenu } from '@/components/DropdownMenu'
import Loader from '@/components/Loader'
import { Pathname } from '@/routes/constants'

import { BaseLayout } from './BaseLayout'
import { getPathnameWithoutTrailingSlash } from './getPathnameWithoutTrailingSlash'

type StepperSteps = React.ComponentProps<typeof Stepper>['steps']

interface StepperLayoutProps {
  children: ReactNode
  isUserPrimaryOwner: boolean
  activeStepIndex?: number
}

export interface OnboardingStepperProps {
  activeStepIndex: number
  steps: StepperSteps
}

const primaryOwnerOnboardingSteps: StepperSteps = [
  { text: 'Welcome' },
  { text: 'Business details' },
  { text: 'Ownership details' },
  { text: 'Account usage' },
  { text: 'Finish up' },
]

const additionalOwnerSteps: StepperSteps = [
  { text: 'Debit card' },
  { text: 'Terms and conditions' },
  { text: 'Finish up' },
]

const getPathnameFromDynamicPath = (currentPath: string) => {
  const dynamicPathnames = [Pathname.OWNERSHIP_DETAILS_ADD_OWNER, Pathname.OWNERSHIP_DETAILS_EDIT_OWNER]
  for (const dynamicPathname of dynamicPathnames) {
    const isMatch = matchPath({ path: dynamicPathname }, currentPath)
    if (isMatch) return dynamicPathname
  }
  return null
}

const additionalOwnerStepIndexMap = new Map<Pathname | string, number>([
  [Pathname.JOIN_TEAM, 0],
  [Pathname.JOIN_TEAM_SHIPPING_ADDRESS, 0],
  [Pathname.JOIN_TEAM_TERMS_AND_CONDITIONS, 1],
  [Pathname.JOIN_TEAM_CELEBRATION, 2],
])

const welcomePagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.WELCOME_GETTING_STARTED, 0],
  [Pathname.WELCOME_EMAIL_VERIFY, 0],
  [Pathname.WELCOME_BUSINESS_TYPE, 0],
  [Pathname.WELCOME_QUALIFIERS, 0],
])

const businessPagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.BUSINESS_DETAILS, 1],
  [Pathname.BUSINESS_DETAILS_ABOUT_YOUR_BUSINESS, 1],
  [Pathname.BUSINESS_DETAILS_DESCRIPTION_AND_INDUSTRY, 1],
  [Pathname.BUSINESS_DETAILS_CONTACT_INFO, 1],
  [Pathname.BUSINESS_DETAILS_EXPECTED_REVENUE, 1],
  [Pathname.BUSINESS_DETAILS_ONLINE_PRESENCE, 1],
])

const ownershipPagesIndexMap = new Map<Pathname | string, number>([
  [Pathname.OWNERSHIP_DETAILS, 2],
  [Pathname.OWNERSHIP_DETAILS_CONTROLLER, 2],
  [Pathname.OWNERSHIP_DETAILS_PRIMARY_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_ADD_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_EDIT_OWNER, 2],
  [Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS, 2],
])

const stepIndexes = new Map<Pathname | string, number>([
  ...welcomePagesIndexMap,
  ...businessPagesIndexMap,
  ...ownershipPagesIndexMap,

  [Pathname.APPLICATION_SUBMIT, 2],

  [Pathname.USAGE_QUESTIONS, 3],
  [Pathname.USAGE_QUESTION_DEBIT_CARD_AMOUNT, 3],
  [Pathname.USAGE_QUESTION_MONTHLY_DEPOSIT_AMOUNT, 3],
  [Pathname.USAGE_QUESTION_CHECK_DEPOSITS, 3],
  [Pathname.USAGE_QUESTION_ACH_PAYMENTS, 3],
  [Pathname.USAGE_QUESTION_WIRE_PAYMENTS, 3],

  [Pathname.DOCUMENT_UPLOAD, 4],
  [Pathname.DOCUMENT_UPLOAD_FORM, 4],
  [Pathname.DOCUMENT_UPLOAD_THANK_YOU, 4],

  [Pathname.FINISH_UP, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_BACK, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_TERMS_AND_CONDITIONS, 5],
  [Pathname.FINISH_UP_FILE_UPLOAD_THANK_YOU, 5],
  [Pathname.APPLICATION_STATUS, 5],
  [Pathname.DEBIT_CARD_DELIVERY_ADDRESS, 5],
  [Pathname.FINISH_UP_TERMS_AND_CONDITIONS, 5],
  [Pathname.FINISH_UP_WELCOME_SCREEN, 5],

  // Join Team/additional owner pages
  ...additionalOwnerStepIndexMap,
])

const pathsWithFaviconInHeader = [
  // The temporary nature of the email verification screen makes back-navigation tricky
  Pathname.WELCOME_GETTING_STARTED,
  Pathname.WELCOME_EMAIL_VERIFY,
  Pathname.WELCOME_BUSINESS_TYPE,

  // Expectation setting pages
  Pathname.BUSINESS_DETAILS,
  Pathname.OWNERSHIP_DETAILS,
  Pathname.USAGE_QUESTIONS,
  Pathname.FINISH_UP,

  // Exit pages
  Pathname.DOCUMENT_UPLOAD_THANK_YOU,
  Pathname.REJECTED,
  Pathname.FINISH_UP_WELCOME_SCREEN,

  // Epiphemeral pages
  Pathname.OAUTH,
  Pathname.APPLICATION_STATUS,

  // Invited owner entry/exit pages
  Pathname.JOIN_TEAM_SHIPPING_ADDRESS,
  Pathname.JOIN_TEAM_CELEBRATION,
]

const OnboardingStepperWeb = ({ activeStepIndex, steps }: OnboardingStepperProps) => (
  <Box
    sx={{
      backgroundColor: '$white1',
      width: '$80',
      paddingVertical: '$16',
      paddingHorizontal: '$12',
      borderRightWidth: 1,
      borderColor: '$charcoal1',
      display: 'flex',
    }}
  >
    <div style={{ position: 'sticky', top: 50, display: 'flex', flexDirection: 'column', gap: 32 }}>
      <Stepper steps={steps} activeStepIndex={activeStepIndex} numbered />
      <ProgressNote note="Your progress is auto-saved" icon={ICONS.BookmarkIcon} />
    </div>
  </Box>
)

export const OnboardingHeaderMobile = (props: { stepperProps?: OnboardingStepperProps } | undefined) => {
  const pathname = window.location.pathname
  const dynamicPathname = getPathnameFromDynamicPath(pathname)
  const pathnameWithoutTrailingSlash = dynamicPathname ?? (getPathnameWithoutTrailingSlash(pathname) as Pathname)
  const goBack = () => window.history.back()

  const currentStepText = props?.stepperProps?.activeStepIndex
    ? props.stepperProps.steps[props.stepperProps.activeStepIndex]?.text ?? ''
    : ''
  const showHeaderFavicon = pathsWithFaviconInHeader.includes(pathnameWithoutTrailingSlash)

  return (
    <Box>
      <Box sx={{ zIndex: 2 }}>
        <MobileHeaderBar
          title={currentStepText}
          leftAction={
            showHeaderFavicon ? (
              <FaviconIcon size={SizeEnum.MD} />
            ) : (
              <Pressable onPress={goBack}>
                <BackArrowIcon size={SizeEnum.MD} />
              </Pressable>
            )
          }
          rightAction={<AppMenu />}
        />
      </Box>
      {props?.stepperProps?.steps.length && (
        <HorizontalProgressBar
          totalStepCount={props.stepperProps.steps.length}
          activeStepIndex={props.stepperProps.activeStepIndex}
        />
      )}
    </Box>
  )
}

export const StepperScreen = ({ children, isUserPrimaryOwner }: StepperLayoutProps) => {
  const { pathname } = useLocation()
  const { isMobileSize } = useMobileScreenSize()

  const dynamicPathname = getPathnameFromDynamicPath(pathname)
  const pathnameWithoutTrailingSlash = dynamicPathname ?? getPathnameWithoutTrailingSlash(pathname)
  const activeStepIndex = stepIndexes.get(pathnameWithoutTrailingSlash)
  const containerFlexDirection = isMobileSize ? 'column' : 'row'

  const steps = isUserPrimaryOwner ? primaryOwnerOnboardingSteps : additionalOwnerSteps

  const stepperProps = { steps, activeStepIndex: activeStepIndex ?? 0 }

  return (
    <BaseLayout stepperProps={stepperProps}>
      <Box sx={{ flexDirection: containerFlexDirection, flex: 1 }}>
        {!isMobileSize ? <OnboardingStepperWeb {...stepperProps} /> : null}
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </Box>
    </BaseLayout>
  )
}
