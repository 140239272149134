import { analytics as segmentAnalytics } from '@northone/segment-js'
import { Configuration, FrontendApi, VerificationFlow } from '@ory/client-fetch'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

import { analytics } from '../analytics/events'
import { client as apolloClient } from '../apollo/apollo-client'
import { IAuthService } from './auth-service'

const oryApiUrl = import.meta.env.REACT_APP_ORY_API_URL
const oryLoginUrl = import.meta.env.REACT_APP_ORY_AUTH_PAGE_URL

const getOrySdk = () => {
  return new FrontendApi(
    new Configuration({
      basePath: oryApiUrl,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }),
  )
}

/**
 * helper function to redirect the user to login
 * @param mode mode paramter to pass to SSO page
 */
const redirectToLogin = async (mode = 'login'): Promise<void> => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('logout')
  searchParams.set('mode', mode)
  searchParams.set('return_to', window.location.href)

  const redirectUrl = `${oryLoginUrl}?${searchParams}`
  console.log(`Redirecting to login: ${redirectUrl}`)
  window.location.replace(redirectUrl)
}

let cachedToken: string | undefined
let cachedTokenCreatedAt: number | undefined

const getTokenSilently = async ({ skipCache }: { skipCache?: boolean } = {}): Promise<string | null> => {
  // use the cached token if less than a minute old
  if (!skipCache && cachedToken && cachedTokenCreatedAt && Date.now() - cachedTokenCreatedAt < 1000 * 60) {
    return cachedToken
  }

  const ory = getOrySdk()

  const session = await ory.toSession({ tokenizeAs: 'jwt_example_template1' }).catch((err) => {
    console.error(err)
    redirectToLogin()
  })

  if (!session?.tokenized) {
    redirectToLogin()

    return null
  }

  cachedToken = session.tokenized
  cachedTokenCreatedAt = Date.now()
  return session.tokenized
}

const logout = async () => {
  analytics.session.end({ deviceType: isMobile ? 'mobile' : 'desktop' })
  segmentAnalytics().reset()

  window.Intercom('shutdown')
  await apolloClient.clearStore()

  const ory = getOrySdk()
  const logoutUrl = await ory
    .createBrowserLogoutFlow({ returnTo: oryLoginUrl })
    .then((data) => data.logout_url)
    .catch(() => {
      // throws an error when the user is already logged out
    })

  if (!logoutUrl) {
    return redirectToLogin()
  }
  window.location.replace(logoutUrl)
}
const renewTokenOrLogout = async (): Promise<string | null> => {
  return getTokenSilently({ skipCache: true })
    .catch(logout)
    .then((token) => token ?? null)
}

const getUserMetadata = async (): Promise<Record<string, string>> => {
  // TODO implement this for Ory, to get the promo code info etc.
  return {}
}

const useRegisterUser = () => {
  // if a user does not exist in the users service, then this hook should register them
  // TODO: implement this for Ory users if necessary
  return { registerUser: async () => undefined, registerUserLoading: false }
}

const useResendVerificationEmail = ({ email }: { email: string }) => {
  const [resendVerificationEmailLoading, setResendVerificationEmailLoading] = useState(false)
  const [resendVerificationEmailError, setResendVerificationEmailError] = useState<Error>()

  const ory = getOrySdk()

  const resendVerificationEmail = async () => {
    setResendVerificationEmailLoading(true)
    await ory
      .createBrowserVerificationFlow({ returnTo: window.location.origin })
      .then((res) => {
        if (res.state === 'email_sent') {
          return
        }
        const csrfToken = getCsrfTokenFromFlow(res)
        return ory.updateVerificationFlow({
          flow: res.id,
          updateVerificationFlowBody: { method: 'link', email, csrf_token: csrfToken },
        })
      })
      .catch((error) => {
        setResendVerificationEmailError(error instanceof Error ? error : new Error('Unknown error'))
      })
    setResendVerificationEmailLoading(false)
  }

  return { resendVerificationEmail, resendVerificationEmailLoading, resendVerificationEmailError }
}

export const OryAuthService: IAuthService = {
  getTokenSilently,
  logout,
  redirectToLogin,
  renewTokenOrLogout,
  getUserMetadata,
  useRegisterUser,
  useResendVerificationEmail,
}

const getCsrfTokenFromFlow = (flow?: VerificationFlow) => {
  if (!flow?.ui.nodes) return
  const csrfTokenNode = flow.ui.nodes.find(
    (node) => node.attributes.node_type === 'input' && node.attributes.name === 'csrf_token',
  )
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const csrfToken: string | undefined =
    csrfTokenNode?.attributes.node_type === 'input' ? csrfTokenNode.attributes.value : undefined
  return csrfToken
}
