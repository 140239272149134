import {
  Box,
  DropdownSmall,
  ICONS,
  IconWithBackground,
  SelectionBoxInputGroup,
  SizeEnum,
} from '@northone/ui-components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { TBusinessTaxClassification, BusinessType } from '@/types'
import { isCorporation } from '@/utils'

type BusinessTypeCategory = 'CORPORATION' | 'LLC' | 'SOLEPROP' | 'PARTNERSHIP'

const businessTypeToBusinessTypeCategory = new Map<BusinessType | null, BusinessTypeCategory | undefined>([
  [BusinessType.CORP, 'CORPORATION'],
  [BusinessType.CCORP, 'CORPORATION'],
  [BusinessType.SCORP, 'CORPORATION'],
  [BusinessType.LLC, 'LLC'],
  [BusinessType.SOLEPROP, 'SOLEPROP'],
  [BusinessType.FREELANCER, 'SOLEPROP'],
  [BusinessType.PARTNERSHIP, 'PARTNERSHIP'],
  [null, undefined],
])

export const WelcomeBusinessType = () => {
  const navigate = useNavigate()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const businessType = useAppSelector((state) => state.application.businessType)
  const businessTaxClassification = useAppSelector((state) => state.application.businessTaxClassification)

  const [businessTypeCategory, setBusinessTypeCategory] = useState<BusinessTypeCategory | undefined>(
    businessTypeToBusinessTypeCategory.get(businessType),
  )

  const title = t('funnel.businessType.title')

  const businessRequiresTaxClassification = isCorporation(businessType)
  const isContinueButtonDisabled = !businessType || (businessRequiresTaxClassification && !businessTaxClassification)

  const shouldShowCorporationSelect = businessTypeCategory === 'CORPORATION' || businessTypeCategory === 'LLC'

  const options = [
    {
      action: t('funnel.businessType.options.corporation.title'),
      description: t('funnel.businessType.options.corporation.description'),
      leftItem: <IconWithBackground icon={ICONS.CertifiedIcon} backgroundColor="$receiveHoneydew" size={SizeEnum.MD} />,
      value: 'CORPORATION' as const,
      testID: 'corporation',
    },
    {
      action: t('funnel.businessType.options.llc.title'),
      description: t('funnel.businessType.options.llc.description'),
      leftItem: <IconWithBackground icon={ICONS.DocumentIcon} backgroundColor="$receiveHoneydew" size={SizeEnum.MD} />,
      value: 'LLC' as const,
      testID: 'llc',
    },
    {
      action: t('funnel.businessType.options.partnership.title'),
      description: t('funnel.businessType.options.partnership.description'),
      leftItem: <IconWithBackground icon={ICONS.HandshakeIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />,
      value: 'PARTNERSHIP' as const,
      testID: 'partnership',
    },
    {
      action: t('funnel.businessType.options.soleProprietorship.title'),
      description: t('funnel.businessType.options.soleProprietorship.description'),
      leftItem: (
        <IconWithBackground icon={ICONS.SoleProprietorIcon} size={SizeEnum.MD} backgroundColor="$receiveHoneydew" />
      ),
      value: 'SOLEPROP' as const,
      testID: 'soleProprietorship',
    },
  ]

  const taxClassificationOptions: { value: TBusinessTaxClassification; text: string; testID: string }[] = [
    { value: 'scorp', text: t('funnel.businessType.SCORP'), testID: 'scorp' },
    { value: 'ccorp', text: t('funnel.businessType.CCORP'), testID: 'ccorp' },
    { value: 'partnership', text: t('funnel.businessType.PARTNERSHIP'), testID: 'partnership' },
  ] as const

  const handleSelectionChange = (value: BusinessTypeCategory) => {
    setBusinessTypeCategory(value)
    dispatch(applicationActions.setBusinessTaxClassification(undefined))
    switch (value) {
      case BusinessType.LLC:
      case BusinessType.PARTNERSHIP:
      case BusinessType.SOLEPROP:
        dispatch(applicationActions.setBusinessType(value as BusinessType))
        break
      case 'CORPORATION':
        dispatch(applicationActions.setBusinessType(BusinessType.CORP))
        break
    }
  }

  const handleDropdownChange = (value: TBusinessTaxClassification) => {
    dispatch(applicationActions.setBusinessTaxClassification(value))
  }

  const onContinue = () => {
    dispatch(applicationActions.setBusinessType(businessType))
    dispatch(applicationActions.setBusinessTaxClassification(businessTaxClassification))
    navigate(Pathname.WELCOME_QUALIFIERS)
  }

  return (
    <BaseContentLayout
      primaryButton={
        <ContinueButton
          testID="business-type-continue"
          onPress={onContinue}
          disabled={isContinueButtonDisabled}
          fullWidth
        />
      }
      headingText={title}
    >
      <>
        <Box sx={{ gap: '$5' }}>
          <SelectionBoxInputGroup
            labelText={t('funnel.businessType.label')}
            options={options}
            value={businessTypeCategory}
            onChange={handleSelectionChange}
          />
          {shouldShowCorporationSelect && (
            <DropdownSmall
              options={taxClassificationOptions}
              placeholderProps={{ text: t('funnel.businessType.taxClassification.placeholder') }}
              value={businessTaxClassification ?? undefined}
              onChange={handleDropdownChange}
              labelText={t('funnel.businessType.taxClassification.label')}
            />
          )}
        </Box>
      </>
    </BaseContentLayout>
  )
}
