import { createMachine } from 'xstate'

import { getIsSoleProp, isCorporation } from '@/utils'

import { OnboardingAccountStatus, OnboardingApplicationStatus, RequiredDocumentEnum } from '../../generated/graphql'
import { IOnboardingWrapper } from './helpers'

/* 
  Please see visualization: https://stately.ai/viz/107944ff-3bb6-4da4-a98e-b78147a8a865 
*/

export const onboardingRouterMachine = (statuses: IOnboardingWrapper) => {
  return createMachine(
    {
      tsTypes: {} as import('./onboarding.typegen').Typegen0,
      id: 'onboarding',
      context: {
        ...statuses,
        error: undefined as boolean | undefined,
      },
      predictableActionArguments: true,
      schema: {
        events: {} as
          | { type: 'MACHINE.GET_ROUTE_NAME'; value: string }
          | { type: 'ACCOUNT.OPENED'; value: string }
          | { type: 'ACCOUNT.NOT.OPENED'; value: string },
      },
      initial: 'accountStatus',
      states: {
        accountStatus: {
          on: {
            'MACHINE.GET_ROUTE_NAME': [
              {
                target: 'opened',
                cond: 'isAccountStatusOpened',
              },
              {
                target: 'notOpened',
              },
            ],
          },
        },
        opened: {
          invoke: {
            src: () => (send) => {
              send('ACCOUNT.OPENED')
            },
          },
          on: {
            'ACCOUNT.OPENED': [
              {
                target: 'WEB_BANKING_REDIRECT',
                cond: 'isAccountStatusOpened',
              },
            ],
          },
        },
        notOpened: {
          invoke: {
            src: () => (send) => {
              send('ACCOUNT.NOT.OPENED')
            },
          },
          on: {
            'ACCOUNT.NOT.OPENED': [
              {
                target: 'EMAIL_VERIFY',
                cond: 'shouldStayOnEmailVerified',
              },
              {
                target: 'WELCOME',
                cond: 'needToRegisterUser',
              },
              { target: 'WELCOME', cond: 'missingWelcomeDetails' },
              {
                target: 'REJECTED',
                cond: 'isRefusedOrRejected',
              },
              {
                target: 'BUSINESS_DETAILS',
                cond: 'missingBusinessDetails',
              },
              {
                target: 'OWNER_DETAILS',
                cond: 'missingOwnerDetails',
              },
              {
                target: 'ACCOUNT_USAGE',
                cond: 'missingUsageQuestions',
              },
              {
                target: 'APPLICATION_STATUS',
                cond: 'hasSubmittedApplication',
              },
              {
                target: 'MANUAL_REVIEW_GATE_MORE_INFO',
                cond: 'isInManualReviewWithMailingAddress',
              },
              {
                target: 'FINISH_UP',
                cond: 'isInManualReviewWithoutMailingAddress',
              },
              {
                target: 'FINISH_UP',
                cond: 'missingMailingAddress',
              },
              {
                target: 'FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT',
                cond: 'isInManualReviewWithMailingAddressAndDocUploadEnabled',
              },
              {
                target: 'FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP',
                cond: 'isInManualReviewWithProofOfOwnershipRequired',
              },
              {
                target: 'FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE',
                cond: 'isInManualReviewWithBusinessLicenseRequired',
              },
              {
                target: 'FINISH_UP',
                cond: 'missingTermsAndConditions',
              },
              {
                target: 'WELCOME_TO_NORTH_ONE',
                cond: 'canMoveToWelcomeScreen',
              },
            ],
          },
        },
        WELCOME: {},
        BUSINESS_DETAILS: {},
        OWNER_DETAILS: {},
        APPLICATION_STATUS: {},
        EMAIL_VERIFY: {},
        ACCOUNT_USAGE: {},
        MAILING_ADDRESS: {},
        MANUAL_REVIEW_GATE_MORE_INFO: {},
        REJECTED: {},
        WELCOME_TO_NORTH_ONE: {},
        WEB_BANKING_REDIRECT: {},
        FINISH_UP: {},
        FINISH_UP_FILE_UPLOAD_PROOF_OF_IDENTITY_FRONT: {},
        FINISH_UP_FILE_UPLOAD_PROOF_OF_OWNERSHIP: {},
        FINISH_UP_FILE_UPLOAD_BUSINESS_LICENSE: {},
      },
    },
    {
      guards: {
        missingTermsAndConditions: (context): boolean => {
          return !context.applicationState.hasAcceptedTermsAndConditions
        },
        missingWelcomeDetails: (context): boolean => {
          const businessType = context.applicationState.businessType
          const businessTaxClassification = context.applicationState.businessTaxClassification
          const isCorp = isCorporation(businessType)
          const prohibitedIndustriesTermsAccepted = context.applicationState.prohibitedIndustriesCheck

          return !businessType || (isCorp && !businessTaxClassification) || !prohibitedIndustriesTermsAccepted
        },
        missingBusinessDetails: (context): boolean => {
          const noTINTermsAcceptance = !context.applicationState.hasAcceptedTINTermsAndConditions
          const isSoleProp = getIsSoleProp(context.applicationState.businessType)

          return !context.applicationState.businessRevenue || (!isSoleProp && noTINTermsAcceptance)
        },
        missingOwnerDetails: (context): boolean => {
          const noTINTermsAcceptance = !context.applicationState.hasAcceptedTINTermsAndConditions
          const isSoleProp = getIsSoleProp(context.applicationState.businessType)

          return !context.applicationStatus || (isSoleProp && noTINTermsAcceptance)
        },
        isAccountStatusOpened: (context): boolean => {
          return context.accountStatus === OnboardingAccountStatus.OPENED
        },

        shouldStayOnEmailVerified: (context): boolean => !context.isEmailVerified,

        isInManualReviewWithMailingAddressAndDocUploadEnabled: (context): boolean =>
          Boolean(
            context.applicationStatus === OnboardingApplicationStatus.REVIEWING &&
              context.hasMailingAddress === true &&
              context.isManualReviewDocUploadEnabled &&
              context.requiredManualReviewFiles?.includes(RequiredDocumentEnum.GOVERNMENT_ID),
          ),
        isInManualReviewWithProofOfOwnershipRequired: (context): boolean =>
          Boolean(
            context.applicationStatus === OnboardingApplicationStatus.REVIEWING &&
              context.hasMailingAddress === true &&
              context.isManualReviewDocUploadEnabled &&
              context.requiredManualReviewFiles?.includes(RequiredDocumentEnum.INCORPORATION_DOC),
          ),
        isInManualReviewWithBusinessLicenseRequired: (context): boolean =>
          Boolean(
            context.applicationStatus === OnboardingApplicationStatus.REVIEWING &&
              context.hasMailingAddress === true &&
              context.isManualReviewDocUploadEnabled &&
              context.requiredManualReviewFiles?.includes(RequiredDocumentEnum.DBA_DOC),
          ),
        isInManualReviewWithMailingAddress: (context): boolean =>
          !context.isManualReviewDocUploadEnabled &&
          context.applicationStatus === OnboardingApplicationStatus.REVIEWING &&
          context.hasMailingAddress === true,
        isInManualReviewWithoutMailingAddress: (context): boolean =>
          context.applicationStatus === OnboardingApplicationStatus.REVIEWING && !context.hasMailingAddress,

        isRefusedOrRejected: (context): boolean =>
          context.applicationStatus === OnboardingApplicationStatus.REFUSED ||
          context.applicationStatus === OnboardingApplicationStatus.REJECTED,

        canMoveToWelcomeScreen: (context): boolean => {
          return (
            context.accountStatus === OnboardingAccountStatus.ACCOUNT_USAGE_SURVEY_SUBMITTED &&
            context.applicationStatus === OnboardingApplicationStatus.APPROVED &&
            context.hasMailingAddress === true
          )
        },

        hasSubmittedApplication: (context): boolean => {
          return context.applicationStatus === OnboardingApplicationStatus.SUBMITTED
        },

        needToRegisterUser: (context): boolean => {
          return !context.applicationState.businessId
        },

        missingUsageQuestions: (context): boolean => {
          return context.accountStatus !== OnboardingAccountStatus.ACCOUNT_USAGE_SURVEY_SUBMITTED
        },

        missingMailingAddress: (context): boolean => {
          return Boolean(
            context.accountStatus === OnboardingAccountStatus.ACCOUNT_USAGE_SURVEY_SUBMITTED &&
              !context.hasMailingAddress,
          )
        },
      },
    },
  )
}
