import rootPkg from '../../../../package.json'
import pkg from '../../package.json'

export const RELEASE = `${pkg.name.replace('@', '')}@${rootPkg.version}`
export const RELEASE_VERSION = rootPkg.version

export const PRODUCTION_STAGE = 'prod'

const stage = import.meta.env.REACT_APP_STAGE || PRODUCTION_STAGE

export const isProduction = (): boolean => stage === PRODUCTION_STAGE

export const config = {
  stage,
  env: import.meta.env.NODE_ENV || 'development',
  graphQlUri: import.meta.env.REACT_APP_GQL_URI || 'https://dobby-graphql.dev.aws.northoneapps.com/',
  segmentWriteKey: import.meta.env.REACT_APP_SEGMENT_KEY || 'O0V6h5uUsGG3hQUF9pBMx48AWO3l73W2',
  optimizelySDK: import.meta.env.REACT_APP_OPTIMIZELY_SDK_KEY || 'DQp7L19gzfmDxWKhnZtNaT',
  publicURL: import.meta.env.PUBLIC_URL,
  sentryDsn: import.meta.env.REACT_APP_SENTRY_DSN,
  onboardingBackendUrl:
    import.meta.env.REACT_APP_ONBOARDING_BACKEND_URL ||
    'https://doritos-onboarding.dev.aws.northoneapps.com/onboarding-data',
  ory: {
    apiUrl: import.meta.env.REACT_APP_ORY_API_URL || 'https://ory.northoneapps.com/',
    authPageUrl: import.meta.env.REACT_APP_ORY_AUTH_PAGE_URL || 'https://ory-auth.dev.aws.northoneapps.com/',
  },
  webBankingUrl: import.meta.env.REACT_APP_WEB_BANKING_URL || 'https://daffy-banking.dev.aws.northoneapps.com/',
  intercomAppId: import.meta.env.REACT_APP_INTERCOM_APP_ID || 'ua4yzghj',
  cardFundingRsaEncryptionKey: import.meta.env.REACT_APP_CARD_RSA_ENCRYPTION_KEY,
  cardFundingRsaEncryptionKeyId: import.meta.env.REACT_APP_CARD_RSA_ENCRYPTION_ID,
  reCAPTCHASiteKey: import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY,
  socureSdkKey: import.meta.env.REACT_APP_SOCURE_SDK_KEY,
  northoneSocureEndpoint: import.meta.env.REACT_APP_NORTHONE_SOCURE_ENDPOINT,
  launchDarklyClientSideId: import.meta.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  googleTagManagerContainerId: import.meta.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID,
}
