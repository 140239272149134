import { useUserContext } from '@/core/auth/user-context'
import { CLOSE_ONBOARDING_WEBVIEW_MESSAGE, isMBAWebview } from '@/utils/detect-mba-webview'

import { IAddressState } from '../../core/redux/application-redux/application-state'
import { Location, LocationStrictInput } from '../../generated/graphql'
import { config } from '../../utils/environment'

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void
    }
  }
}

export const gqlLocationToAddressState = (gqlLocation?: Location | null): IAddressState => ({
  city: gqlLocation?.city ?? '',
  state: gqlLocation?.provinceState ?? '',
  streetAddress: gqlLocation?.streetAddressLine1 ?? '',
  suite: gqlLocation?.streetAddressLine2 ?? '',
  zipCode: gqlLocation?.postalCode ?? '',
  description: '',
  coordinates: null,
})

export const gqlLocationToLocationInput = ({
  city,
  streetAddressLine1,
  streetAddressLine2,
  provinceState,
  postalCode,
}: Location): LocationStrictInput => ({
  streetAddressLine1: streetAddressLine1 ?? '',
  streetAddressLine2: streetAddressLine2 ?? '',
  city: city ?? '',
  country: 'US',
  postalCode: postalCode ?? '',
  provinceState: provinceState ?? '',
})

export const navigateToWebBanking = () => window.location.replace(config.webBankingUrl)

export const useNavigateToWebBankingOrMBA = (): (() => Promise<void>) => {
  const user = useUserContext()
  const email = user?.email
  //Handle navigation back to app in MBA Onboarding Webview
  const navigate = async () => {
    if (isMBAWebview && window.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: CLOSE_ONBOARDING_WEBVIEW_MESSAGE,
          payload: { email },
        }),
      )
    }

    window.location.replace(config.webBankingUrl)
  }

  return navigate
}
