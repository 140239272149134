import {
  ATMCardIcon,
  Body,
  BodySizeEnum,
  Box,
  Checkbox,
  ExchangeIcon,
  GamblingIcon,
  IdentificationIcon,
  MarijuanaIcon,
  MoveMoneyIcon,
  SizeEnum,
} from '@northone/ui-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { analytics } from '@/core/analytics/events'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useAppSelector } from '@/core/redux/utils'
import useBusinessType from '@/hooks/useBusinessType'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { useKeyboardEvent } from '@/utils/hooks'

const iconTextContainerStyles = {
  flexDirection: 'row',
  gap: '$2',
}

const CitizenshipBlock = () => {
  const t = useOnboardingTranslations()
  const { isSoleProp } = useBusinessType()

  const declarationText = isSoleProp
    ? t('funnel.citizenship.declarationSoleprop')
    : t('funnel.citizenship.declarationNonSoleprop')

  return (
    <Box sx={{ gap: '$2' }}>
      <Body size={BodySizeEnum.LG}> {t('funnel.citizenship.title')}</Body>
      <Box sx={iconTextContainerStyles}>
        <IdentificationIcon color={'$charcoal5'} size={SizeEnum.MD} />
        <Body size={BodySizeEnum.MD}>{declarationText}</Body>
      </Box>
    </Box>
  )
}

const prohibitedIndustriesList = [
  { icon: <MoveMoneyIcon color={'$charcoal5'} size={SizeEnum.MD} />, textKey: 'MSB' },
  { icon: <ATMCardIcon color={'$charcoal5'} size={SizeEnum.MD} />, textKey: 'ATM' },
  { icon: <ExchangeIcon color={'$charcoal5'} size={SizeEnum.MD} />, textKey: 'virtualCurrency' },
  { icon: <MarijuanaIcon color={'$charcoal5'} size={SizeEnum.MD} />, textKey: 'marijuana' },
  { icon: <GamblingIcon color={'$charcoal5'} size={SizeEnum.MD} />, textKey: 'gambling' },
] as const

const ProhibitedIndustriesListDeclaration = () => {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const { isSoleProp } = useBusinessType()
  const isCheckBoxSelected = useAppSelector((state) => state.application.prohibitedIndustriesCheck)

  const handleCheckboxChange = () => {
    dispatch(applicationActions.setProhibitedIndustriesCheck(!isCheckBoxSelected))
  }

  const checkboxLabelText = isSoleProp
    ? t('funnel.prohibitedIndustries.checkBoxTextSoleprop')
    : t('funnel.prohibitedIndustries.checkBoxTextNonSoleprop')

  return (
    <Box sx={{ gap: '$8' }}>
      <Box sx={{ gap: '$2' }}>
        <Box>
          <Body size={BodySizeEnum.LG}>{t('funnel.prohibitedIndustries.title')}</Body>
        </Box>
        {prohibitedIndustriesList.map((item, index) => (
          <Box key={index} sx={iconTextContainerStyles}>
            {item.icon}
            <Body size={BodySizeEnum.MD}>{t(`funnel.prohibitedIndustries.list.${item.textKey}`)}</Body>
          </Box>
        ))}
      </Box>
      <Box sx={{ alignItems: 'flex-start' }}>
        <Checkbox
          size="LG"
          testID="checkbox"
          isChecked={isCheckBoxSelected}
          value={'prohibited-activites-declaration'}
          labelText={checkboxLabelText}
          onChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  )
}

export const Qualifiers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useOnboardingTranslations()

  const isCheckBoxSelected = useAppSelector((state) => state.application.prohibitedIndustriesCheck)

  useKeyboardEvent({
    onTab: () => dispatch(applicationActions.setProhibitedIndustriesCheck(!isCheckBoxSelected)),
  })

  const onGoBack = () => {
    navigate(Pathname.WELCOME_BUSINESS_TYPE)
  }

  const disabled = !isCheckBoxSelected

  const onContinue = () => {
    if (isCheckBoxSelected) {
      analytics.funnel.prohibitedIndustry()
      navigate(Pathname.BUSINESS_DETAILS)
    }
  }

  const title = t('funnel.qualifiers.title')

  return (
    <BaseContentLayout
      headingText={title}
      primaryButton={<ContinueButton testID="continue-button" disabled={disabled} fullWidth onPress={onContinue} />}
      secondaryButton={<GoBackButton testID="go-back-button" fullWidth onPress={onGoBack} />}
    >
      <CitizenshipBlock />
      <ProhibitedIndustriesListDeclaration />
    </BaseContentLayout>
  )
}
