import { useAppSelector } from '@/core/redux/utils'
import { getIsSoleProp, isCorporation, isPartnership as getIsPartnership } from '@/utils'

export default function useBusinessType() {
  const businessType = useAppSelector((state) => state.application.businessType)

  const isCorp = isCorporation(businessType)
  const isSoleProp = getIsSoleProp(businessType)
  const isPartnership = getIsPartnership(businessType)

  return { businessType, isCorp, isSoleProp, isPartnership }
}
