import { Body, BodySizeEnum, Box, Checkbox } from '@northone/ui-components'
import { useDispatch } from 'react-redux'

import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'

export const TINDisclaimer = ({ hasAcceptedTINTermsAndConditions }: { hasAcceptedTINTermsAndConditions: boolean }) => {
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()
  const handleCheckboxChange = () =>
    dispatch(applicationActions.setTINTermsAndConditionsAccepted(!hasAcceptedTINTermsAndConditions))

  const copy = {
    label: t('ownership.profile.disclaimers.tinAttestation.perjury'),
    firstCondition: t('ownership.profile.disclaimers.tinAttestation.correctID'),
    secondCondition: t('ownership.profile.disclaimers.tinAttestation.backupWithholding'),
    thirdCondition: {
      heading: t('ownership.profile.disclaimers.tinAttestation.coverage.intendedToCover'),
      conditions: [
        t('ownership.profile.disclaimers.tinAttestation.coverage.usCitizen'),
        t('ownership.profile.disclaimers.tinAttestation.coverage.partnership'),
        t('ownership.profile.disclaimers.tinAttestation.coverage.estate'),
      ],
    },
    fourthCondition: t('ownership.profile.disclaimers.tinAttestation.exemptFromFATCA'),
    fifthCondition: t('ownership.profile.disclaimers.tinAttestation.unableToOpenAccount'),
    acknowledgment: t('ownership.profile.disclaimers.tinAttestation.acknowledgment'),
  }

  return (
    <Box sx={{ flexDirection: 'column', gap: '$1' }}>
      <Checkbox
        isChecked={hasAcceptedTINTermsAndConditions}
        onChange={handleCheckboxChange}
        value="hasAcceptedTINTermsAndConditions"
        labelText={copy.label}
        testID="tin-attestation-checkbox"
      />

      <Box sx={{ gap: '$2', pl: '$6' }}>
        <Box>
          <TINDisclaimerBullet bulletNumber={1} text={copy.firstCondition} />
          <TINDisclaimerBullet bulletNumber={2} text={copy.secondCondition} />
          <TINDisclaimerBullet bulletNumber={3} text={copy.thirdCondition.heading} />
          <Box sx={{ pl: '$7' }}>
            {copy.thirdCondition.conditions.map((condition, index) => (
              <TINDisclaimerBullet key={index} text={condition} />
            ))}
          </Box>
          <TINDisclaimerBullet bulletNumber={4} text={copy.fourthCondition} />
          <TINDisclaimerBullet bulletNumber={5} text={copy.fifthCondition} />
        </Box>

        <Body size={BodySizeEnum.SM}>{copy.acknowledgment}</Body>
      </Box>
    </Box>
  )
}

const TINDisclaimerBullet = ({ bulletNumber, text }: { bulletNumber?: number; text: string }) => {
  const bulletDecoration = bulletNumber ? `${bulletNumber}.` : '•'

  return (
    <Box sx={{ flexDirection: 'row', gap: '$1' }}>
      <Box sx={{ w: bulletNumber ? '$4' : '$2' }}>
        <Body size={BodySizeEnum.SM}>{bulletDecoration}</Body>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Body size={BodySizeEnum.SM}>{text}</Body>
      </Box>
    </Box>
  )
}
