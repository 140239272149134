import { interpret } from 'xstate'

import { OnboardingApplicationStatus } from '../../generated/graphql'
import { DetermineInitialRouteProps, RouteNameOrRouteObjectReturnType } from './types'

export const determineInitialRouteByMachine = ({
  stateMachine,
  accountStatus,
  applicationStatus,
  isEmailVerified,
  hasMailingAddress,
  applicationState,
  hasTeamMemberShippingAddress,
  isOnboardingComplete,
  isManualReviewDocUploadEnabled,
  requiredManualReviewFiles,
}: DetermineInitialRouteProps): RouteNameOrRouteObjectReturnType => {
  const isApproved = applicationStatus === OnboardingApplicationStatus.APPROVED
  const isRejected = applicationStatus === OnboardingApplicationStatus.REJECTED
  const isInManualReview = applicationStatus === OnboardingApplicationStatus.REVIEWING
  const isApplicationVerified = isApproved || isInManualReview

  const machine = stateMachine({
    accountStatus,
    applicationStatus,
    isApproved,
    isRejected,
    isInManualReview,
    isApplicationVerified,
    isEmailVerified,
    hasMailingAddress,
    applicationState,
    hasTeamMemberShippingAddress,
    isOnboardingComplete,
    isManualReviewDocUploadEnabled,
    requiredManualReviewFiles,
  })

  // @ts-expect-error
  const service = interpret(machine)
  service.start()
  service.send('MACHINE.GET_ROUTE_NAME')
  const route: RouteNameOrRouteObjectReturnType = service.getSnapshot().value
  service.stop()

  return route
}
