import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'

import { analytics } from '../analytics/events'

export interface FeatureFlags {
  'joindot-funding-urgency-enabled': boolean
  'joindot-apy-enabled': boolean
  'joindot-welcome-bonus-enabled': boolean
}

const featureFlagFallbacks: FeatureFlags = {
  'joindot-funding-urgency-enabled': false,
  'joindot-apy-enabled': false,
  'joindot-welcome-bonus-enabled': false,
}

export type FeatureFlagKey = keyof FeatureFlags

const secondsUntilTimeout = 5

export const useFeatureFlag = <FlagKey extends FeatureFlagKey>(flagKey: FlagKey) => {
  const flags = useFlags<FeatureFlags>()
  const result = flags[flagKey]
  const [hasTimedOut, setHasTimedOut] = useState(false)
  const fallbackValue = featureFlagFallbacks[flagKey]

  useEffect(() => {
    if (result === undefined) return

    const sendAnalyticsEvent = async () => {
      await analytics.featureFlagEvaluated({ featureFlag: flagKey, result })
    }
    void sendAnalyticsEvent()
  }, [flagKey, result])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (result !== undefined) return
      console.warn(`Feature flag ${flagKey} is undefined after ${secondsUntilTimeout} seconds. Using fallback value.`)
      setHasTimedOut(true)
    }, secondsUntilTimeout * 1000)

    return () => clearTimeout(timeout)
  }, [result, flagKey])

  return hasTimedOut ? fallbackValue : result
}
