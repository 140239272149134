import { createMachine } from 'xstate'

import { additionalOwnerRoutes } from '../../routes/join-team/state-machine/config'
import { IOnboardingWrapper } from './helpers'

/* 
  Please see visualization: https://stately.ai/viz/d3a80994-2c08-4cf1-975f-bfe30cf452b2
*/

export const additionalOwnersRouterMachine = (statuses: IOnboardingWrapper) => {
  return createMachine(
    {
      tsTypes: {} as import('./additionalOwners.typegen').Typegen0,
      id: 'additionalOwner',
      context: {
        ...statuses,
        error: undefined as boolean | undefined,
      },
      schema: {
        events: {} as { type: 'MACHINE.GET_ROUTE_NAME'; value: string },
      },
      initial: 'additionalOwnerStatus',
      predictableActionArguments: true,
      states: {
        additionalOwnerStatus: {
          on: {
            'MACHINE.GET_ROUTE_NAME': [
              {
                target: additionalOwnerRoutes.shipping_address.path,
                cond: 'missingShippingAddress',
              },
              {
                target: additionalOwnerRoutes.terms_and_conditions.path,
                cond: 'missingTermsAndConditions',
              },
              {
                target: additionalOwnerRoutes.celebration.path,
                cond: 'missingAdditionalOnboardingCompletedEvent',
              },
              {
                target: 'WEB_BANKING_REDIRECT',
                cond: 'canRedirectToWB',
              },
            ],
          },
        },
        [additionalOwnerRoutes.shipping_address.path]: {},
        [additionalOwnerRoutes.terms_and_conditions.path]: {},
        [additionalOwnerRoutes.celebration.path]: {},
        WEB_BANKING_REDIRECT: {},
      },
    },
    {
      guards: {
        missingShippingAddress: (context): boolean => {
          return !context.hasTeamMemberShippingAddress
        },
        missingTermsAndConditions: (context): boolean => {
          return !context.applicationState.hasAcceptedTermsAndConditions
        },
        missingAdditionalOnboardingCompletedEvent: (context): boolean => {
          return Boolean(
            context.hasTeamMemberShippingAddress &&
              context.applicationState.hasAcceptedTermsAndConditions &&
              !context.isOnboardingComplete,
          )
        },
        canRedirectToWB: (context): boolean => {
          return context.isOnboardingComplete
        },
      },
    },
  )
}
