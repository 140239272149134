import emailMisspelled, { top100 } from 'email-misspelled'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton'
import GoBackButton from '@/components/GoBackButton'
import { useAppSelector } from '@/core/redux/utils'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'

import { OwnerProfileForm } from './OwnerProfileForm'
import { OwnerProfileInputIDs } from './constants'
import { useOwnerProfileAnalytics } from './utils/owner-profile-analytics'
import { scrollToInput, useOwnerProfileValidation } from './utils/owner-validation-hooks'

export const OwnershipEditOwner = () => {
  const params = useParams()

  const ownerId = params.ownerId
  if (!ownerId) throw new Error('OwnerId not found')

  const t = useOnboardingTranslations()
  const navigate = useNavigate()

  const heading = t('ownership.profile.coOwnerHeading')

  const { hasError, showErrors, setShowErrors, scrollToFirstInputWithError } = useOwnerProfileValidation({ ownerId })
  const { sendAnalyticsEvent } = useOwnerProfileAnalytics(ownerId)

  const [emailSuggestion, setEmailSuggestion] = useState<string | undefined>(undefined)
  const [wasAlertDismissed, setWasAlertDismissed] = useState(false)

  const owners = useAppSelector((state) => state.application.owners)
  const ownerEmail = owners[ownerId]?.email ?? ''
  const emailChecker = emailMisspelled({ maxMisspelled: 1, domains: top100 })

  const onEmailSuggestionAccepted = () => {
    setEmailSuggestion(undefined)
  }
  const onEmailSuggestionDismiss = () => {
    setEmailSuggestion(undefined)
    setWasAlertDismissed(true)
  }

  const onContinue = () => {
    setShowErrors(true)
    if (hasError) return scrollToFirstInputWithError()
    // Check if the entered email has a suggestion that has not been dismissed
    const suggestion = emailChecker(ownerEmail).map((result) => result.corrected)[0]
    if (ownerEmail && suggestion && !wasAlertDismissed) {
      setEmailSuggestion(suggestion)
      // Scroll to the email input field so the user can review and correct their email
      const emailInputEl = document.getElementById(OwnerProfileInputIDs.email)
      emailInputEl && scrollToInput(emailInputEl)
      return
    }
    sendAnalyticsEvent()
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }
  const onBack = () => {
    navigate(Pathname.OWNERSHIP_DETAILS_REVIEW_OWNERS)
  }

  const isContinueButtonDisabled = Boolean(emailSuggestion && !wasAlertDismissed)
  const primaryButton = (
    <ContinueButton
      testID="owner-profiles-edit-owner-continue"
      onPress={onContinue}
      fullWidth
      disabled={isContinueButtonDisabled}
    />
  )
  const secondaryButton = <GoBackButton testID="owner-profiles-edit-owner-back" onPress={onBack} fullWidth />

  return (
    <BaseContentLayout primaryButton={primaryButton} secondaryButton={secondaryButton} headingText={heading}>
      <OwnerProfileForm
        ownerId={ownerId}
        shouldShowValidationErrors={showErrors}
        emailSuggestion={emailSuggestion}
        onEmailSuggestionAccepted={onEmailSuggestionAccepted}
        onEmailSuggestionDismiss={onEmailSuggestionDismiss}
      />
    </BaseContentLayout>
  )
}
