import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '@/components/LoadingScreen'
import { BaseLayout } from '@/layouts/BaseLayout'

import { AuthServiceContext, setAuthService } from './auth-service'
import { OryAuthService } from './ory-auth-service'

interface AuthWrapperProps {
  children?: React.ReactNode
}
export const AuthWrapper = ({ children }: AuthWrapperProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuthSession = async () => {
      // redirect to login on logout
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get('logout')) {
        return OryAuthService.redirectToLogin()
      }

      try {
        const token = await OryAuthService.getTokenSilently()
        if (token) {
          setIsAuthenticated(true)
          return
        }
        await OryAuthService.redirectToLogin('signup')
        return
      } catch (error) {
        console.error('Error checking auth session', error)
        return await OryAuthService.redirectToLogin('signup')
      }
    }

    void checkAuthSession()
  }, [OryAuthService, navigate])

  if (!isAuthenticated)
    return (
      <BaseLayout>
        <LoadingScreen />
      </BaseLayout>
    )

  setAuthService(OryAuthService)
  return <AuthServiceContext.Provider value={OryAuthService}>{children}</AuthServiceContext.Provider>
}
