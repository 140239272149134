import { AlertTypeEnum, Box, Checkbox, InlineAlert, Link } from '@northone/ui-components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import ContinueButton from '@/components/ContinueButton.tsx'
import GoBackButton from '@/components/GoBackButton.tsx'
import Loader from '@/components/Loader.tsx'
import LoadingScreen from '@/components/LoadingScreen.tsx'
import { applicationActions } from '@/core/redux/application-redux/application-actions'
import { useOnboardingTranslations } from '@/i18n/locales/en/en'
import { BaseContentLayout } from '@/layouts/BaseContentLayout'
import { Pathname } from '@/routes/constants'
import { LinkURL } from '@/routes/welcome/constants'

import { analytics } from '../core/analytics/events'
import { useAppSelector } from '../core/redux/utils'
import {
  TermsAndConditionsScreenQuery,
  useOnboardingAgreementsTermsAndConditionsAcceptMutation,
  useTermsAndConditionsScreenQuery,
} from '../generated/graphql'

const PDF_HEIGHT = 274
const PDF_MAX_WIDTH = 548

const iframeStyles = {
  minHeight: PDF_HEIGHT,
  width: PDF_MAX_WIDTH,
  maxWidth: '100%',
  height: '100%',
}

const termsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderColor: '$charcoal1',
  borderWidth: 1,
  borderRadius: '$sm',
  height: PDF_HEIGHT,
  width: '$full',
  maxWidth: PDF_MAX_WIDTH,
}

interface TermsAndConditionsPDFProps {
  termsAndConditionsPDFUrl: string | undefined | null
}

const TermsPDF = ({ termsAndConditionsPDFUrl }: TermsAndConditionsPDFProps) => {
  return (
    <Box sx={termsContainerStyles}>
      {termsAndConditionsPDFUrl ? <iframe style={iframeStyles} src={termsAndConditionsPDFUrl} /> : <Loader />}
    </Box>
  )
}

interface TermAndConditionsLinksProps {
  accountAgreementPDFURL: string | undefined
  esignAgreementPDFURL: string | undefined
  mobilePaymentServicesAgreementPDFURL: string | undefined
}

const TermAndConditionsLinksSection = ({
  accountAgreementPDFURL,
  esignAgreementPDFURL,
  mobilePaymentServicesAgreementPDFURL,
}: TermAndConditionsLinksProps) => {
  const t = useOnboardingTranslations()

  return (
    <Box sx={{ marginLeft: '$8', gap: '$0.5' }}>
      <Link isExternal href={esignAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.esign')}
      </Link>
      <Link isExternal href={accountAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.accountAgreement')}
      </Link>
      <Link isExternal href={LinkURL.PRIVACY_AND_COOKIE_POLICY}>
        {t('funnel.termsAndConditions.checkBoxText.privacyCookiePolicy')}
      </Link>
      <Link isExternal href={mobilePaymentServicesAgreementPDFURL}>
        {t('funnel.termsAndConditions.checkBoxText.walletServices')}
      </Link>
      <Link isExternal href={LinkURL.WELCOME_BONUS}>
        {t('funnel.termsAndConditions.checkBoxText.welcomeBonus')}
      </Link>
      <Link isExternal href={LinkURL.APY_PROGRAM_TERMS}>
        {t('funnel.termsAndConditions.checkBoxText.apyTerms')}
      </Link>
    </Box>
  )
}

export default function SharedTermsAndConditions({ nextPage }: { nextPage: Pathname }) {
  const businessId = useAppSelector((state) => state.application.businessId)
  const navigate = useNavigate()
  const location = useLocation()
  const t = useOnboardingTranslations()
  const dispatch = useDispatch()

  const title = t('funnel.termsAndConditions.title')

  const isCheckBoxSelected = useAppSelector((state) => state.application.hasAcceptedTermsAndConditions)
  const handleCheckboxChange = () => {
    dispatch(applicationActions.setTermsAndConditionsAccepted(!isCheckBoxSelected))
  }

  const [accountAgreementPDFURL, setAccountAgreementPDFURL] = useState<string>()
  const [esignAgreementPDFURL, setEsignAgreementPDFURL] = useState<string>()
  const [mobilePaymentServicesAgreementPDFURL, setMobilePaymentServiceAgreementPDFURL] = useState<string>()
  const [termsAndConditionsData, setTermsAndConditionsData] =
    useState<TermsAndConditionsScreenQuery['termsAndConditionsPDF']>()

  const [shouldShowError, setShouldShowError] = useState(false)
  const [shouldShowLoadingScreen, setShouldShowLoadingScreen] = useState(false)
  const showError = () => setShouldShowError(true)
  const ErrorBanner = () => {
    return <InlineAlert type={AlertTypeEnum.Error}>{t('error.generic')}</InlineAlert>
  }

  const { loading: queryLoading, error: queryError } = useTermsAndConditionsScreenQuery({
    onError: showError,
    onCompleted: (data) => {
      setAccountAgreementPDFURL(data.accountAgreement.pdfURL)
      setEsignAgreementPDFURL(data.esignAgreement.pdfURL)
      setMobilePaymentServiceAgreementPDFURL(data.mobilePaymentServicesAgreement.pdfURL)
      setTermsAndConditionsData(data.termsAndConditionsPDF)
    },
  })

  const termsDocumentReference = termsAndConditionsData?.documentReference

  const [acceptTermsAndConditions] = useOnboardingAgreementsTermsAndConditionsAcceptMutation()

  if (queryError) {
    return <InlineAlert type={AlertTypeEnum.Error}>{t('funnel.termsAndConditions.error')}</InlineAlert>
  }

  const termsAndConditionsPDFUrl = termsAndConditionsData?.url
    ? `${termsAndConditionsData?.url}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0&view=FitH`
    : undefined

  const onContinue = async () => {
    setShouldShowError(false)
    if (!termsDocumentReference) {
      return showError()
    }
    setShouldShowLoadingScreen(true)
    analytics.funnel.termsConditions()
    const acceptTermsAndNavigateToNextPage = async () => {
      try {
        await acceptTermsAndConditions({
          variables: { documentReference: termsDocumentReference, businessId },
        })
        navigate(nextPage)
      } catch (_error) {
        await acceptTermsAndNavigateToNextPage()
      }
    }

    await acceptTermsAndNavigateToNextPage()
  }

  if (shouldShowLoadingScreen) {
    return <LoadingScreen title="Saving your information" />
  }

  const onGoBack = () => {
    const isInitialLocation = location.key === 'default'
    isInitialLocation ? navigate(Pathname.FINISH_UP) : navigate(-1)
  }

  return (
    <BaseContentLayout
      alertComponent={shouldShowError ? <ErrorBanner /> : null}
      headingText={title}
      primaryButton={
        <ContinueButton
          testID="terms-continue"
          onPress={onContinue}
          fullWidth
          loading={queryLoading || !termsAndConditionsPDFUrl}
          disabled={!isCheckBoxSelected || !termsDocumentReference}
        />
      }
      secondaryButton={<GoBackButton testID="terms-go-back" onPress={onGoBack} fullWidth />}
    >
      <TermsPDF termsAndConditionsPDFUrl={termsAndConditionsPDFUrl} />
      <Box sx={{ gap: '$1' }}>
        <Checkbox
          size="LG"
          testID="checkbox"
          isChecked={isCheckBoxSelected}
          value={'welcome-terms-declaration'}
          labelText={t('funnel.termsAndConditions.checkBoxText.acknowledge')}
          onChange={handleCheckboxChange}
        />
        <TermAndConditionsLinksSection
          accountAgreementPDFURL={accountAgreementPDFURL}
          esignAgreementPDFURL={esignAgreementPDFURL}
          mobilePaymentServicesAgreementPDFURL={mobilePaymentServicesAgreementPDFURL}
        />
      </Box>
    </BaseContentLayout>
  )
}
